import { UserProjectDisplaySettingsDto } from "~/api/contracts/models";

export function useAnonymizedText (text: string, settings: undefined | Ref<undefined | UserProjectDisplaySettingsDto>) {
    const result = ref(text);

    watch ([settings], () => {
        if (settings && settings.value && settings.value.isDemoModeEnabled) {
            let newResult = text;
            
            if (!text)
            {
                return
            }

            if (settings.value.anonymizationRules) {
                const orderedRules = _orderBy(settings.value.anonymizationRules, x => x.key.length, 'desc')

                for (const rule of orderedRules) {
                    newResult = newResult.replaceAll(rule.key!, rule.value!)
                }
            }
    
            result.value = newResult;
        }
    }, { deep: true, immediate: true })
    
    return result
}
